<template>
  <div
    class="
      justify-between
      flex
      bg-black
      items-center
      h-auto
      w-full
      px-8
      md:px-16
      py-2
      fixed
      bottom-0
    "
  >
    <div class="text-gray-200 font-thin uppercase text-xs tracking-wider">
      Vue.js + Tailwind
    </div>
    <a
      href="http://sbliss.design"
      class="
        text-base text-white
        font-bold
        lowercase
        text-gray-200
        hover:text-white
      "
      >sbliss.design</a
    >
  </div>
</template>
