<template>
  <div class="justify-center flex bg-orange-600 items-center h-auto p-8">
    <div class="text-4xl text-white font-bold">Mars Rover Pictures</div>
  </div>
  <div
    class="
      sticky
      top-0
      z-50
      justify-between
      flex
      bg-orange-800
      items-center
      h-auto
      px-8
      md:px-16
      py-2
    "
  >
    <div class="text-xl text-white font-bold">
      {{ rover }}
    </div>

    <div class="font-light uppercase text-sm text-white">
      {{ camera }} Camera
    </div>

    <div class="font-light uppercase text-sm text-white">{{ earthdate }}</div>
  </div>

  <div class="bg-gray-100 items-center h-auto py-8 px-8 md:px-16">
    <form>
      <div class="flex flex-wrap items-end flex-col md:flex-row -mx-3 mb-0">
        <div class="w-full md:w-2/5 px-3 mb-4 md:mb-0">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >Rover
          </label>
          <div class="relative">
            <select
              @change="onChange"
              class="
                block
                appearance-none
                w-full
                bg-gray-200
                border border-gray-200
                text-gray-700
                py-3
                px-4
                pr-8
                h-12
                rounded
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
              "
              id="select-rover"
              v-model="rover"
            >
              <option value="Curiosity">Curiosity</option>
              <option value="Opportunity">Opportunity</option>
              <option value="Spirit">Spirit</option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="w-full md:w-2/5 px-3 mb-4 md:mb-0">
          <label
            class="
              block
              uppercase
              tracking-wide
              text-gray-700 text-xs
              font-bold
              mb-2
            "
            >Camera
          </label>
          <div class="relative">
            <select
              @change="onChange"
              class="
                block
                appearance-none
                w-full
                bg-gray-200
                border border-gray-200
                text-gray-700
                py-3
                px-4
                pr-8
                h-12
                rounded
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
              "
              id="select-rover"
              v-model="camera"
            >
              <option value="FHAZ">Front Hazard Avoidance Camera</option>
              <option value="RHAZ">Rear Hazard Avoidance Camera</option>
              <option value="NAVCAM">Navigation Camera</option>
              <option v-if="Curiosity" value="MAST">Mast Camera</option>
              <option v-if="Curiosity" value="CHEMCAM">
                Chemistry and Camera Complex
              </option>
              <option v-if="Curiosity" value="MAHLI">
                Mars Hand Lens Imager
              </option>
              <option v-if="Curiosity" value="MARDI">
                Mars Descent Imager
              </option>
              <option v-if="Opportunity || Spirit" value="PANCAM">
                Panoramic Camera
              </option>
              <option v-if="Opportunity || Spirit" value="MINITES">
                Miniature Thermal Emission Spectrometer
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/5 px-3 mb-4 md:mb-0">
          <div class="px-0 relative">
            <label
              class="
                pointer-events-none
                block
                uppercase
                tracking-wide
                text-gray-700 text-xs
                font-bold
                mb-2
                relative
              "
              >Sol

              <span
                @mouseover="datehover = true"
                @mouseleave="datehover = false"
                class="
                  pointer-events-auto
                  p-0
                  text-center text-xs
                  ml-2
                  bg-gray-400
                  hover:bg-lime-500
                  rounded
                  w-4
                  h-4
                  inline-block
                  text-white
                  cursor-pointer
                "
              >
                ?
              </span>
            </label>

            <input
              @focusout="onChange"
              v-model="date"
              class="
                top-0
                block
                relative
                appearance-none
                w-full
                bg-gray-200
                border border-gray-200
                text-gray-700
                py-3
                px-4
                pr-8
                h-12
                rounded rounded-r-none
                leading-tight
                focus:outline-none
                focus:bg-white
                focus:border-gray-500
              "
            />

            <div
              @click="dateInc"
              class="
                absolute
                right-1
                bottom-6
                rounded-tr
                text-center
                bg-transparent
                h-6
                w-6
                font-bold
                cursor-pointer
                text-gray-400
                hover:text-gray-500
              "
            >
              <svg
                class="fill-current transform rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
            <div
              @click="dateDec"
              class="
                absolute
                right-1
                bottom-0
                rounded-br
                text-center
                bg-transparent
                h-6
                w-6
                font-bold
                cursor-pointer
                text-gray-400
                hover:text-gray-500
              "
            >
              <svg
                class="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div v-if="nopictures" class="items-center h-auto p-8 mt-8">
    <p class="text-center text-lg">
      I didn't take any pictures with this camera today.
    </p>
  </div>

  <div class="grid grid-cols-3 md:grid-cols-8 gap-2 mx-8 md:mx-16 py-8">
    <div
      class="w-full rounded cursor-pointer"
      v-for="picture in pictures"
      :key="picture.id"
    >
      <img
        class="rounded-lg"
        v-bind:src="picture.img_src"
        @click="toggleModal(picture.img_src)"
      />
    </div>
  </div>

  <div
    v-if="modal"
    @click.self="toggleModal"
    class="fixed z-50 w-screen h-screen top-0 overflow-auto bg-smoke-dark flex"
  >
    <div
      class="
        animate-fade-in-down
        fixed
        relative
        align-top
        w-auto
        m-auto
        justify-center
        p-0
        bg-gray-100
        border border-white
        rounded
        h-auto
        shadow
        flex flex-col
        overflow-hidden
      "
    >
      <img v-bind:src="pic" class="lightbox" />
      <div
        class="
          font-mono
          text-black text-sm
          md:text-base
          absolute
          w-full
          left-0
          uppercase
          bottom-0
          bg-white bg-opacity-80
          px-4
          py-2
        "
      >
        {{ rover }} – {{ camera }} – {{ earthdate }}
      </div>
      <span @click="toggleModal" class="absolute top-0 right-0 p-2">
        <svg
          class="h-8 w-8 text-white hover:text-grey-darkest fill-current"
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <title>Close</title>
          <path
            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
          />
        </svg>
      </span>
    </div>
  </div>

  <div
    v-if="datehover"
    class="
      animate-fade-in-down
      fixed
      bg-gray-100
      border border-lime-500
      text-black
      p-4
      text-base
      inset-x-2
      md:inset-x-1/4
      top-40
      z-50
      rounded
      normal-case
    "
  >
    <p><strong>Martian Sol</strong></p>
    Photos are organized by the sol (Martian rotation or day) on which they were
    taken, counting up from the rover's landing date (0).
  </div>
  <VueTWfooter></VueTWfooter>
</template>

<script>
import axios from "axios";
import VueTWfooter from "./components/VueTWfooter.vue";
export default {
  name: "App",
  components: { VueTWfooter },
  data() {
    return {
      pictures: [],
      rover: "Curiosity",
      date: "0",
      camera: "FHAZ",
      modal: false,
      datehover: false,
      pic: "",
      earthdate: "",
      nopictures: true,
      Curiosity: true,
      Opportunity: false,
      Spirit: false,
    };
  },
  created() {
    this.getpics();
  },
  methods: {
    onChange() {
      this.Curiosity = this.rover == "Curiosity" ? true : false;
      this.Opportunity = this.rover == "Opportunity" ? true : false;
      this.Spirit = this.rover == "Spirit" ? true : false;

      this.date = parseInt(this.date);

      this.getpics();
    },
    dateInc() {
      this.date++;
      this.getpics();
    },
    dateDec() {
      this.date--;
      if (this.date < 0) {
        this.date = 0;
      }
      this.getpics();
    },
    toggleModal(pic) {
      this.pic = pic;
      this.modal = !this.modal;
    },
    async getpics() {
      try {
        const res = await axios.get(
          "https://api.nasa.gov/mars-photos/api/v1/rovers/" +
            this.rover +
            "/photos?sol=" +
            this.date +
            "&camera=" +
            this.camera +
            "&api_key=4OyRNcQAZupLtst7sgDCn3MUldJcvqQLZTmTrDuz"
        );
        this.pictures = res.data.photos;
        if (this.pictures.length > 0) {
          this.earthdate = this.pictures[0].earth_date;
          this.nopictures = false;
        } else {
          this.earthdate = "";
          this.nopictures = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
